@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');
body {
  margin: 0;
  /* background-image: url('./Images/Background.jpg');  set the background image */
  /* background-size: cover;  scale the image to cover the body */
  background-position: center;  /* center the image */
  background-repeat: no-repeat;  /* prevent the background image from repeating */
  background-attachment: fixed;  /* make the background image fixed */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;  /* align items at the top */
  height: auto;  /* adjust height based on content */
  width: 50%;  /* adjust width to take up full width */
  background-color: rgba(0, 0, 0, 0);  /* semi-transparent black background */
  padding: 30px;  /* add some padding to create a "window" effect */
  box-sizing: border-box;  /* include padding in the container's size */
  border: 3px solid rgba(0, 0, 0, 0);  /* semi-transparent black border */
  margin: 15vh auto;  /* adjust vertical margin to fit taller container */
  border-radius: 20px;  /* add rounded corners */
  flex-wrap: wrap; /* allow the items to wrap onto the next line if there's not enough space */
}


.logo-container {
  position: absolute;
  top: 1vmin;  /* add margin from the top */
  right: 1vmin;  /* add margin from the right */
  display: flex;  /* use flexbox */
  flex-direction: column;  /* stack the items vertically */
  align-items: center;  /* center the items horizontally */
}

.logo {
  width: 20vmin;  /* adjust as needed */
  height: 20vmin;  /* make the height equal to the width */
  border-radius: 50%;  /* make the image round */
  object-fit: contain;  /* scale the image to be contained within the element */
}

.logo-text {
  margin-top:1vmin;
  font-family: 'Dancing Script', cursive;  /* use a hand writing font */
  font-size: 3vmin;  /* adjust as needed */
  text-align: right;  /* align the text to the right */
}


.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);  /* semi-transparent black background */
  color: white;
  text-align: center;
  padding: 5px 0;  /* reduce padding to make the footer smaller */
  margin-top: auto;  /* push the footer to the bottom */
}

.card {
  width: 20vw;  /* reduce width to make cards narrower */
  height: 20vh;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.matrix-input input[type='number']::-webkit-inner-spin-button,
.matrix-input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.matrix-input input[type='number'] {
  -moz-appearance: textfield;
}

.matrix-input input[type='number'] {
  padding: 0;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

a {
  text-decoration: none;
}


.tableCell[id="myTableCellId"] {
  border: 2px solid #000;
  width: 8vmin;
  height: 8vmin;
  vertical-align: middle;
  text-align: center;
  padding: 0.2vmin;
  box-sizing: border-box;
  font-size: 3vmin;
  line-height: 1vmin;
  verticalAlign: 'middle';
  boxSizing: 'border-box'; 
  padding: 0; 
  marginL: 0; 
  marginR: 0; 
  /* If the aspect ratio is less than 1 (height is greater than width), use vw units */
@media (aspect-ratio: 1/1) {
  .tableCell[id="myTableCellId"] {
    width: 8vw;
    height: 8vw;
  }
}

/* If the aspect ratio is greater than 1 (width is greater than height), use vh units */
@media (aspect-ratio: 1/1) {
  .tableCell[id="myTableCellId"] {
    width: 8vh;
    height: 8vh;
  }
}
  
}